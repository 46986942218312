import React, { Fragment, useState, useEffect, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import GoogleTranslate from '../googleTranslater';
import logo from '../../../assets/images/bcompliant_logo.png';
import logo_compact from '../../../assets/images/bcompliant_logo_compact.png';
import MENUITEMS from '../../../constant/menu';
import configDB from '../../../data/customizer/config';
import auth from '../../services/authService';
import { SocketContext } from '../../../context/socket';
import Guard from '../guard';
import creService from '../../services/creService';
import { settingsKey } from '../../services/authService'

const settings = JSON.parse(localStorage.getItem(settingsKey))

const Sidebar = (props) => {

  const socket = useContext(SocketContext);
  const locationEnv = useMemo(() => auth.getCurrentUser()?.locationEnv, []);

  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true);
  const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const wrapper = configDB.data.settings.sidebar.wrapper;
  const layout = useSelector((content) => content.Customizer.layout);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [currentPortfolio, setCurrentPortfolio] = useState(
    localStorage.getItem('portfolioId')
  );
  const [portfolios, setPortfolios] = useState([]);

  const [locationEnvs, setLocationEnvs] = useState([]);

  useEffect(() => {
    window?.addEventListener('resize', handleResize);
    handleResize();
    fetchPortfolioData();
    fetchLocationEnvs();
    localStorage.setItem('portfolioId', currentPortfolio);
    var currentUrl = window.location.pathname;

    // eslint-disable-next-line
    mainmenu.filter((items) => {
      if (items.path === currentUrl) setNavActive(items);
      if (!items.children) return false;
      // eslint-disable-next-line
      items.children.filter((subItems) => {
        if (subItems.path === currentUrl) setNavActive(subItems);
        if (!subItems.children) return false;
        // eslint-disable-next-line
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === currentUrl) setNavActive(subSubItems);
        });
      });
    });

    const elmnt = document.getElementById('myDIV');
    const menuWidth = elmnt.offsetWidth;
    // setMenuWidth(menuWidth)
    if (menuWidth > window.innerWidth) {
      setHideRightArrow(false);
      setHideLeftArrowRTL(false);
    } else {
      setHideRightArrow(true);
      setHideLeftArrowRTL(true);
    }


    return () => {
      // eslint-disable-next-line
      window?.addEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, []);

  const fetchPortfolioData = async () => {
    try {
      const { data } = await creService.getPortfolioData();
      setPortfolios(data.data);
    } catch (err) {
      toast.error(err.message);
    }
  };
  const fetchLocationEnvs = async () => {
    try {
      const { data } = await creService.getLocationEnvs();
      setLocationEnvs(data.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleResize = () => {
    setWidth(window.innerWidth - 310);
  };

  const setNavActive = (item) => {
    // eslint-disable-next-line
    MENUITEMS.filter((menuItem) => {
      // eslint-disable-next-line
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children) menuItem.active = true;
      if (menuItem.children) {
        // eslint-disable-next-line
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  // Click Toggle menu
  const toggletNavActive = (item) => {
    if (!item.active) {
      MENUITEMS.forEach((a) => {
        if (a !== item) {
          a.active = false;
        }
        if (a.children) {
          a.active = true;
        }
        if (!a.children) return false;
        a.children.forEach((b) => {
          b.active = false;
          if (!b.children) return false;
          b.children.forEach((c) => {
            if (b.children.includes(item)) {
              c.active = false;
            }
          });
        });
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  const scrollToRight = () => {
    const elmnt = document.getElementById('myDIV');
    const menuWidth = elmnt.offsetWidth;
    const temp = menuWidth + margin;
    if (temp < menuWidth) {
      setMargin(-temp);
      setHideRightArrow(true);
    } else {
      setMargin((margin) => (margin += -width));
      setHideLeftArrow(false);
    }
  };

  const scrollToLeft = () => {
    // If Margin is reach between screen resolution
    if (margin >= -width) {
      setMargin(0);
      setHideLeftArrow(true);
    } else {
      setMargin((margin) => (margin += width));
      setHideRightArrow(false);
    }
  };

  const scrollToLeftRTL = () => {
    if (margin <= -width) {
      setMargin((margin) => (margin += -width));
      setHideLeftArrowRTL(true);
    } else {
      setMargin((margin) => (margin += -width));
      setHideRightArrowRTL(false);
    }
  };

  const scrollToRightRTL = () => {
    const temp = width + margin;
    // Checking condition for remaing margin
    if (temp === 0) {
      setMargin(temp);
      setHideRightArrowRTL(true);
    } else {
      setMargin((margin) => (margin += width));
      setHideRightArrowRTL(false);
      setHideLeftArrowRTL(false);
    }
  };

  const user = auth.getCurrentUser();

  useEffect(() => {
    if (socket) {
      socket.emit('get-chat-message-unread-count', user);

      const handler = (msg) => {
        setUnreadMessages(msg);
      };
      socket.on('chat-message-unread-count', handler);
      return () => socket.off('chat-message-unread-count', handler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePortfolio = (e) => {
    setCurrentPortfolio(e.target.value);
    localStorage.setItem('portfolioId', e.target.value);
    window.location.reload(false);
    toggletNavActive({});
  };
  const changeLocationEnvs = async (e) => {

    try {
      const { data } = await creService.setLocationEnv(e.target.value);
      if (data.status) {
        localStorage.setItem('cre-token', data.token);
        window.location.reload(false);
      } else {
        toast.error(data.message);
      }

    } catch (err) {
      toast.error(err.message);
    }


  };

  return (
    <Fragment>
      <div className="page-sidebar">
        <div className="main-header-left d-none d-lg-block">
          <div className="logo-wrapper compactLogo">
            <Link to="/dashboard/" onClick={() => toggletNavActive({})}>
              <img className="image-logo" src={logo_compact} alt="" />
              <div className="image-logo position-relative">
                <span className=" position-absolute" style={{
                  fontSize: '14px', left: '36px', top: '24px', color:'rgb(0,91,169)', fontWeight: '700'
                }}> {(locationEnv !== 'NYC') && locationEnv}</span>
                <img className="image-logo" src={logo} alt="" />
              </div>

            </Link>
          </div>
          {/* <span className="badge badge-light" style={{ fontSize: '14px' }}> {locationEnv}</span> */}
        </div>

        <div className="sidebar custom-scrollbar">
          {/*<UserPanel />*/}
          <ul
            className="sidebar-menu"
            id="myDIV"
            style={
              wrapper === 'horizontal_sidebar'
                ? layout === 'rtl'
                  ? { marginRight: margin + 'px' }
                  : { marginLeft: margin + 'px' }
                : { margin: '0px' }
            }
          >

            {locationEnvs.length > 1 && (
              <li className="mb-3">
                <select
                  value={locationEnv}
                  onChange={(e) => changeLocationEnvs(e)}
                  name="locationEnvs"
                  className="form-control"
                >
                  {locationEnvs.map((key) => (
                    <option value={key} key={key}>
                      {key}
                    </option>
                  ))}

                </select>
              </li>
            )}
            {portfolios?.length > 0 && (
              <li className="mb-3">
                <select
                  value={(currentPortfolio === 'null' || !currentPortfolio) ? "All" : currentPortfolio}
                  onChange={(e) => changePortfolio(e)}
                  name="reportStatus"
                  className="form-control"
                >
                  {portfolios.map((item) => (
                    <option value={item._id} key={item._id}>
                      {item.portfolioName}
                    </option>
                  ))}
                  <option value="All">Show All</option>
                </select>
              </li>
            )}


            <li
              className={`left-arrow ${layout === 'rtl'
                ? hideLeftArrowRTL
                  ? 'd-none'
                  : ''
                : hideLeftArrow
                  ? 'd-none'
                  : ''
                }`}
              onClick={
                wrapper === 'horizontal_sidebar' && layout === 'rtl'
                  ? scrollToLeftRTL
                  : scrollToLeft
              }
            >
              <i className="fa fa-angle-left"></i>
            </li>
            {MENUITEMS.map((menuItem, i) => (
              <Guard
                key={`guard_${i}`}
                serviceName={menuItem.serviceName}
                action={menuItem.action}
                allow={() => (
                  <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
                    {menuItem.sidebartitle ? (
                      <div className="sidebar-title">
                        {menuItem.sidebartitle}
                      </div>
                    ) : (
                      ''
                    )}
                    {menuItem.type === 'sub' ? (
                      <Link
                        className="sidebar-header"
                        to={menuItem.path ? menuItem.path : '#'}
                      >
                        <menuItem.icon />
                        <span>{props.t(menuItem.title)}</span>
                        {menuItem.beta && (
                          <sup className="ml-2 text-danger">Beta</sup>
                        )}
                      </Link>
                    ) : (
                      ''
                    )}
                    {menuItem.type === 'link' ? (
                      menuItem.serviceName && menuItem.action ? (
                        <Guard
                          serviceName={menuItem.serviceName}
                          action={menuItem.action}
                          allow={() => (
                            <Link
                              to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                              className={`sidebar-header ${menuItem.active ? 'active' : ''
                                }`}
                              onClick={() => toggletNavActive(menuItem)}
                            >
                              <menuItem.icon />
                              <span>
                                {props.t(menuItem.title)}
                                {['Chats'].includes(menuItem.title) &&
                                  unreadMessages > 0 && (
                                    <sup className="badge badge-danger ml-1 text-white">
                                      {unreadMessages}
                                    </sup>
                                  )}
                              </span>
                              {menuItem.children ? (
                                <i className="fa fa-angle-right pull-right"></i>
                              ) : (
                                ''
                              )}
                            </Link>
                          )}
                        />
                      ) : (
                        <Link
                          to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                          className={`sidebar-header ${menuItem.active ? 'active' : ''
                            }`}
                          onClick={() => toggletNavActive(menuItem)}
                        >
                          <menuItem.icon />
                          <span>
                            {props.t(menuItem.title)}
                            {['Chats'].includes(menuItem.title) &&
                              unreadMessages > 0 && (
                                <sup className="badge badge-danger ml-1 text-white">
                                  {unreadMessages}
                                </sup>
                              )}
                          </span>
                          {menuItem.children ? (
                            <i className="fa fa-angle-right pull-right"></i>
                          ) : (
                            ''
                          )}
                        </Link>
                      )
                    ) : (
                      ''
                    )}
                    {menuItem.children ? (
                      <ul
                        className={`sidebar-submenu ml-5 ${menuItem.active ? 'menu-open' : ''
                          }`}
                        style={
                          menuItem.active
                            ? {
                              opacity: 1,
                              transition: 'opacity 500ms ease-in',
                            }
                            : {}
                        }
                      >
                        {menuItem.children.map(
                          (childrenItem, index) =>
                            ((childrenItem.subUserResctricted &&
                              !user?.isSubUser) ||
                              !childrenItem.subUserResctricted) && (
                              <Guard
                                key={`guard_sub_link_item_${index}`}
                                serviceName={childrenItem.serviceName}
                                action={childrenItem.action}
                                allow={() => (
                                  <li
                                    key={`sidebar_menu_items_${index}`}
                                    className={
                                      childrenItem.children
                                        ? childrenItem.active
                                          ? 'active'
                                          : ''
                                        : ''
                                    }
                                  >
                                    {childrenItem.type === 'sub' ? (
                                      <a
                                        href="#javascript"
                                        onClick={() =>
                                          toggletNavActive(childrenItem)
                                        }
                                      >
                                        <i className="fa fa-circle"></i>
                                        {props.t(childrenItem.title)}{' '}
                                        <i className="fa fa-angle-right pull-right"></i>
                                      </a>
                                    ) : (
                                      ''
                                    )}

                                    {childrenItem.type === 'link' ? (
                                      <Link
                                        to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                                        className={
                                          childrenItem.active ? 'active' : ''
                                        }
                                        onClick={() =>
                                          toggletNavActive(childrenItem)
                                        }
                                      >
                                        <i className="fa fa-circle"></i>
                                        {props.t(childrenItem.title)}{' '}
                                      </Link>
                                    ) : (
                                      ''
                                    )}
                                    {childrenItem.children ? (
                                      <ul
                                        className={`sidebar-submenu ${childrenItem.active
                                          ? 'menu-open'
                                          : 'active'
                                          }`}
                                      >
                                        {childrenItem.children.map(
                                          (childrenSubItem, key) => (
                                            <li
                                              className={
                                                childrenSubItem.active
                                                  ? 'active'
                                                  : ''
                                              }
                                              key={`sidebar_${childrenSubItem.type}_${key}`}
                                            >
                                              {childrenSubItem.type ===
                                                'link' ? (
                                                <Link
                                                  to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                                  className={
                                                    childrenSubItem.active
                                                      ? 'active'
                                                      : ''
                                                  }
                                                  onClick={() =>
                                                    toggletNavActive(
                                                      childrenSubItem
                                                    )
                                                  }
                                                >
                                                  <i className="fa fa-circle"></i>
                                                  {props.t(
                                                    childrenSubItem.title
                                                  )}
                                                </Link>
                                              ) : (
                                                ''
                                              )}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    ) : (
                                      ''
                                    )}
                                  </li>
                                )}
                              />
                            )
                        )}
                      </ul>
                    ) : (
                      ''
                    )}
                  </li>
                )}
              />
            ))}
            <li
              className={`right-arrow ${layout === 'rtl'
                ? hideRightArrowRTL
                  ? 'd-none'
                  : ''
                : hideRightArrow
                  ? 'd-none'
                  : ''
                }`}
              onClick={
                wrapper === 'horizontal_sidebar' && layout === 'rtl'
                  ? scrollToRightRTL
                  : scrollToRight
              }
            >
              <i className="fa fa-angle-right"></i>
            </li>
            {
              settings?.googleTranslater && <li>
                <GoogleTranslate />
              </li>
            }
          </ul>

        </div>
      </div >
    </Fragment >
  );
};

export default translate(Sidebar);
