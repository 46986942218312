import React, { Fragment, useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import moment from 'moment'

import Block from './block'
import creService from '../../services/creService';
import Outstanding from './OutstandingFines';
import SnapshotDataMenu from './snapshotDataMenu'

const OpenViolationsBlocks = [
  'DOB',
  'DEP',
  'SWO',
  'VO',
  'HPD',
  'FDNY',
  'ECB-DOB',
  'DOT',
  'DSNY',
  'Hearings',
  'DOH',
  'V.Orders',
  'Corrections',
  'HPD-Cert',
  'BcompliantRetained',
]

const Violation = (props) => {


  let type = 'Open Violations';

  const [data, setData] = useState(
    props.jestData !== undefined
      ? props.jestData.data
      : {
        SWO: { total: '' },
        VO: { total: '' },
        HPD: { total: '' },
        DOB: { total: '' },
        FDNY: { total: '' },
        'ECB-DOB': { total: '' },
        DOT: { total: '' },
        DSNY: { total: '' },
        DEP: { total: '' },
        DOH: { total: '' },
        'V.Orders': { total: '' },
        'Criminal S': { total: '' },
        Corrections: { total: '' },
        BcompliantRetained: { total: '' },
        DOS: { total: '' },
        Hearings: { total: '' },
      }
  );
  const [snapshotData, setSnapshotData] = useState()


  useEffect(() => {
    if (props.selectedDate) {
      const date = moment(new Date(props.selectedDate)).format('L')
      creService.getDashboardDataSnapshot(type, OpenViolationsBlocks, date).then((res) => {
        setSnapshotData(res.data);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedDate])

  useEffect(() => {


    creService.getDashboardData(type).then((res) => {
      const data = OpenViolationsBlocks.reduce((ac, a) => {
        ac[a] = {
          total: res.data[a]?.totalAllTime,
          label:  res.data[a].label
        }
        return ac
      }, {})
      setData(data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doughnutData = {
    labels: OpenViolationsBlocks,
    datasets: [
      {
        data: OpenViolationsBlocks.map(el => data[el]?.total),
        backgroundColor: [
          '#edc536',
          '#ce5370',
          '#a3437b',
          '#f69746',
          '#eb6f5e',
          '#8a8163',
          '#359eed',
          '#7cb4c5',
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#3d71ef',
          '#32a852',
          '#1a4463',
          '#ffc0cb',
        ],
        hoverBackgroundColor: [
          '#edc536',
          '#ce5370',
          '#a3437b',
          '#f69746',
          '#eb6f5e',
          '#8a8163',
          '#359eed',
          '#7cb4c5',
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#3d71ef',
          '#32a852',
          '#1a4463',
          '#ffc0cb'
        ],
      },
    ],
  };

  function dashboardDetails(sub) {
    props.history.push(`/dashboard/${type}/${sub}`);
  }

  return (
    <Fragment>

      <div className="row">
        <div className="col-md-6">

          <div className="row">
            {OpenViolationsBlocks.map(el =>
              <Fragment key={el}>
                <Block
                  sub={el}
                  data={data}
                  dashboardDetails={dashboardDetails}
                  selectedDate={props.selectedDate}
                  snapshotData={snapshotData}
                  snapshotOnClickHandler={(sub) => props.snapshotOnClickHandler(sub, type)}
                />
              </Fragment>
            )}
          </div>
          <div className='d-flex justify-content-end'>
            <SnapshotDataMenu selectedDate={props.selectedDate} setSelectedDate={(date) => { props.setSelectedDate(date); setSnapshotData() }} />
          </div>
        </div>
        <div className="col-md-6">
          <Outstanding history={props.history} />
          <div className="pie-chart">
            <Doughnut
              data={doughnutData}
              options={{
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                  display: true,
                  position: 'bottom',
                },
              }}
              width={1000}
              height={900}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Violation;
