import React, { useState, useEffect, useContext, useRef } from "react";
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
} from 'reactstrap';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import './chat.css';
import creApi from '../../../services/creService';
import auth from '../../../services/authService';
import { toast } from "react-toastify";
import moment from "moment";
import { SocketContext } from '../../../../context/socket';
import ReactLoading from "react-loading";

const Message = ({ user, type, senderName, message, date }) => {
    return (
        <div className={`chat-message ${type.toLowerCase()} ${message.isPrivate ? 'private' : ''}`} style={{ whiteSpace: 'pre-wrap' }}>
            {message.message}
            <div className="author">
                Sent by {senderName} at {moment(message.date).format('LT')} on {moment(message.date).format('MM/DD/YYYY')}
            </div>
            {message.isPrivate && (<span className="chat-badge chat-badge-private"><i className="fa fa-key" /> Private</span>)}
            {(!message.viewers.includes(user._id)) &&
                <span className="chat-badge chat-badge-new" style={message.isPrivate ? { marginRight: 65 } : {}}>New</span>
            }
        </div>
    );
};

const ChatThread = ({ id, jestData = false, setChatCount, source }) => {

    const socket = useContext(SocketContext);

    const user = auth.getCurrentUser();
    const location = useLocation();

    const [sending, setSending] = useState(false);
    const [message, setMessage] = useState("");
    const [isPrivate, setIsPrivate] = useState(false);
    const [chats, setChats] = useState(jestData ? jestData.chats : []);
    const scrollRef = useRef(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (id) {
            chatHistoryData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, location])

    const chatHistoryData = async () => {
        setLoading(true);
        try {


            let results = await creApi.getChatHistory(id);
            setChats(results.data.messages);
            if (location.hash === "#chatMessage") {
                window.scrollTo(0, document.body.scrollHeight);
            }
            scrollRef.current?.scrollIntoView({ behavior: "smooth" });
        } catch (e) {
            toast.error(e.message);
        }
        setLoading(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = {
            id,
            message,
            isPrivate
        }
        setSending(true);
        try {
            const res = await creApi.sendChatMessage(data, source);
            if (res.data.status === true) {
                setMessage('');
                setIsPrivate(false);
                document.getElementById("send-message-from-thread").reset();
                // Adding New Message
                if (!socket || source) addNewChatMessage(res.data?.chatMessage);
            }
            setSending(false);
        } catch (_e) {
            toast.error(_e);
            setSending(false);
        }
    }

    useEffect(() => {
        if (socket) {
            socket.on('chat-message', (message) => message.newMessage.violationId === id && addNewChatMessage(message.newMessage));
            return () => socket.off('chat-message', (message) => message.newMessage.violationId === id && addNewChatMessage(message.newMessage));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    const addNewChatMessage = (chatMessage) => {
        setChats((oldChats) => [
            ...oldChats,
            chatMessage,
        ]);
        setChatCount && setChatCount(chats.length + 1)
        setTimeout(() => {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }, 100)
    }

    return (
        <div className="chat-wrapper">
            <div className="messages-wrapper">
                {loading ? <div align="center">
                    <ReactLoading type={"cylon"} color={"grey"} />
                </div> :
                    _.uniqBy(chats, '_id').map((chat, index) => <Message
                        key={index}
                        user={jestData ? jestData.user : user}
                        senderName={chat.senderType === 'admin' ? `${chat.sender.name} (support)` : (chat.sender?.fullName || '[Removed User]')}
                        type={chat.senderType === 'admin' ? 'received' : 'sent'}
                        message={chat}
                    />)

                }
            </div>
            <Form className="send-message" id="send-message-from-thread" onSubmit={(e) => handleSubmit(e)}>
                <FormGroup>
                    <Label htmlFor="chatMessage">{source === 'documents' ? 'Add Comment' : <>Enter your message <sup className="text-danger">*</sup></>}</Label>
                    <Input type="textarea" required name="text" id="chatMessage" onKeyUp={(e) => setMessage(e.target.value)} />
                </FormGroup>
                {source !== 'documents' && <FormGroup check>
                    <Label check>
                        <Input type="checkbox" checked={isPrivate} onChange={(e) => setIsPrivate(e.target.checked)} />{' '} Private Message
                    </Label>
                    <FormText color="muted">
                        By Making Private the message will be visible to you and support team only.
                    </FormText>
                    <div ref={scrollRef}></div>
                </FormGroup>
                }
                <FormGroup className="mt-3">
                    <Button type="submit" color="darkblue" disabled={sending}>{sending ? "Sending..." : "Send"}</Button>
                </FormGroup>
            </Form>
        </div>
    )
}

export default ChatThread;
