import React, { Fragment } from 'react';
import Moment from "moment";
import { Link } from 'react-router-dom'

import Helper from "../../../services/helperService";
import RetainedBadges from './retainedBadges';
import CofCProgress from "./cofcProgress";
import CustomFields from "./customFields";
import CoveredItemsTable from "./coveredItemsTable"
import PermitsAdditionalTable from "./permitsAdditionalTable"


import WaterTanksBlock from './waterTanksBlock'

const DetailsAllOther = ({
  category,
  sub,
  tableRawData,
  apiData,
  convertedData,
  subUsers,
  vendors,
  customFields,
  setRefreshHistory,
  noHeader,
  moveToCategory
}) => {

  const { retained, cofc } = apiData?.violationDetails?.clientAdditionalData || {};

  const retainedTypes = retained ? Object.keys(retained).filter(key => retained[key].isRetained) : []

  const data = Object.entries(convertedData)

  return (
    // All Other Violations Show General Design
    data.map(([paragraphName, values], key) => {

      return <Fragment key={key + 100}>
        <div className="card user-card"
          style={retainedTypes.length > 0 ? { border: '1px solid #c36c00', borderRadius: 0 } : {}}>
          <RetainedBadges retained={retained} />
          {
            data.length > 1 &&
            <div className="card-header">
              <div className="online-user"><h5 className="font-primary f-18 mb-0">{paragraphName}</h5></div>
            </div>
          }


          <div className="card-body ecb-head">
            {!noHeader &&

              <h5 onClick={moveToCategory} className="text-info mb-3 pointer">{category}/{sub}</h5>

            }
            <div className='row ecb-row'>

              {values.map((value, key) => {

                let displayValue = tableRawData[value.apiField];

                // Converting Date
                if (displayValue && ((value.apiField.search(/date/i) !== -1) || (value.displayName.search(/date/i) !== -1)) && typeof displayValue !== 'undefined') {
                  if (Moment(displayValue).isValid()) {
                    displayValue = Moment(displayValue).format('LL');
                  } else {
                    displayValue = '--';
                  }
                }

                // Check if we need to add $ sign
                if (displayValue && value.dollarAmount) {
                  displayValue = `${Helper.formatter.format(parseFloat(displayValue))}`;
                }


                // eslint-disable-next-line
                if (typeof displayValue === 'string' && displayValue.search(/http/i) !== -1) {
                  displayValue =
                    <a className="badge badge-info" rel="noopener noreferrer" href={displayValue} target="_blank">

                      {value.apiField.includes('pdf') ? 'Open in new tab ' : 'Make a Payment '}
                      <i className="fa fa-external-link" /></a>;
                }



                // ECB Ticket#, let's add violation URL
                if ((value.apiField.search(/Ticket_number/i) !== -1) ) {
                  if(category === '311') {
                    displayValue = <>
                    <div>{displayValue} </div>
                    <a className="badge badge-info" rel="noopener noreferrer"
                      href={'https://portal.311.nyc.gov/sr-details/?srnum=311-' + displayValue}
                      target="_blank">
                      View Ticket <i className="fa fa-external-link" />
                    </a>
                  
                  </>;
                  } else {
                    displayValue = <>
                    <div>{displayValue} </div>
                    <a className="badge badge-info" rel="noopener noreferrer"
                      href={'http://a820-ecbticketfinder.nyc.gov/GetViolationImage?violationNumber=' + displayValue}
                      target="_blank">
                      View Image of Summons/Notice <i className="fa fa-external-link" />
                    </a>
                    {(tableRawData.issuing_agency === "DEPT. OF BUILDINGS") &&
                      <a className="badge badge-info" rel="noopener noreferrer"
                        href={`http://a810-bisweb.nyc.gov/bisweb/ECBQueryByNumberServlet?requestid=1&allbin=${this.state.addressData.bin}&ecbin=${displayValue}`}
                        target="_blank">
                        DOB <i className="fa fa-external-link" />
                      </a>
                    }

                    <a className="badge badge-info" rel="noopener noreferrer"
                      href={`https://account.bcompliant.com/redirect/ecb.html?violation_id=${displayValue}`}
                      target="_blank">
                      ECB <i className="fa fa-external-link" />
                    </a>
                  </>;
                  }
              
                }

                if (typeof displayValue === 'object' && displayValue.length) {
                  displayValue = displayValue.join?.(', ')

                } else {
                  displayValue = displayValue || '--'
                }

                return (
                  <div className={`mt-2 ${(['description'].includes(value.displayName.toLowerCase())) ? 'col-12 col-md-12' : 'col-6 col-md-4'}  mb-3`} key={key}>
                    <h6>{value.displayName} {value.link && <Link to={value.link}>
                      <i className=" fa fa-external-link" aria-hidden="true"></i>
                    </Link>
                    }</h6>
                    <p className="rounded d-flex flex-column">
                      {displayValue}
                    </p>
                  </div>)

              })

              }

              {apiData.violationDetails.violationData?.coveredItems?.length ? <CoveredItemsTable items={apiData.violationDetails.violationData.coveredItems} /> : null}
              {apiData.violationDetails.violationData?.DOBApprovedPermits?.length ? <PermitsAdditionalTable items={apiData.violationDetails.violationData.DOBApprovedPermits} /> : null}

              {
                apiData.violationDetails.sub === 'Water_Tank' && apiData.violationDetails.category === 'Compliance' ?
                  <WaterTanksBlock violationData={apiData.violationDetails.violationData} /> : null
              }
              {apiData?.violationDetails?.additionalData?.bcompliantNotes &&
                <div className="ecb-notes">
                  <h5>
                    BCompliant Violation Notes
                  </h5>
                  <div className="desc">
                    <p>
                      {apiData.violationDetails.additionalData.bcompliantNotes}
                    </p>
                  </div>
                  {apiData.violationDetails.bcompliantNotesUpdatedBy && <small className="text-muted">
                    Last updated By {apiData.violationDetails.bcompliantNotesUpdatedBy.name}
                  </small>}
                </div>
              }
            </div>
          </div>
          <CustomFields
            saveOn={category === 'Compliance' ? 'customCompliance' : undefined}
            customFields={customFields}
            subUsers={subUsers}
            vendors={vendors}
            category={category}
            sub={sub}
            apiData={apiData}
            setRefreshHistory={setRefreshHistory}
          />
          <CofCProgress cofc={cofc} />
        </div>
      </Fragment>
    })
  );
};

export default DetailsAllOther;
